import type { LinksFunction } from "@remix-run/cloudflare";
import { json } from "@remix-run/cloudflare";
import type { LoaderFunctionArgs } from "@remix-run/cloudflare";
import { Auth0Provider } from '@auth0/auth0-react';
import { useLoaderData, useLocation, useNavigate, useRouteError } from "@remix-run/react";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
} from "@remix-run/react";
import stylesheet from "~/tailwind.css?url";
import toast, { Toaster } from 'react-hot-toast';
import calendarCSS from "react-datepicker/dist/react-datepicker.css?url";
import { createElement, useEffect } from "react";
import { getSessionStorage } from "~/utils/kvSessions";
import Favicon from "~/images/favicon.png"
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { APPLICATION_VERSION, APPLICATION_ENV } from "~/utils/appversion.client";
import Intercom from '@intercom/messenger-js-sdk'
import { authenticator } from "./utils/auth.server";
import * as Sentry from "@sentry/browser";

export const links: LinksFunction = () => [
  {
    rel: "icon",
    href: Favicon,
    type: "image/png",
  },
  { rel: "stylesheet", href: stylesheet },
  { rel: 'stylesheet', href: calendarCSS },
];

const isClient = typeof document !== "undefined";

export function ErrorBoundary() {

  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  
  if (isClient) {
    return createElement("html", {
      // Inject the server-side rendered HTML.
      dangerouslySetInnerHTML: {
        __html: document.getElementsByTagName("html")[0].innerHTML,
      },
    });
  }
  
  const navigate = useNavigate()
  const goBack = () => navigate(-1)
  return (
    <html suppressHydrationWarning={true}>
      <head>
        <title>Oh no!</title>
        <Meta />
        <Links />
      </head>
      <body>
        <br /><br />
        <div className="text-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 text-primary-yellow text-center block mx-auto">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
          </svg>
        </div>
        <p className="text-4xl text-center font-bold">Something went wrong</p>
        <p className="text-center">We are already working on fixing it</p>
        <br />
        <div className="text-center">
          <a href="javascript:history.back()" className="text-center mx-auto text-white font-bold bg-primary-blue hover:bg-hover-blue py-3 px-4 rounded">Go Back</a>
        </div>
        <Scripts />
      </body>
    </html>
  );
}

export async function loader({ context, request }: LoaderFunctionArgs) {

  const url = new URL(request.url)
  let baseUrl = `${url.protocol}//${url.hostname}`
  if(url.hostname == "localhost"){
    baseUrl += ':8788'
  }

  let sessionStorage = getSessionStorage({context});
  const session = await sessionStorage.getSession(
    request.headers.get("Cookie")
  )
  const userId = session.get("userid")

  let userData = null
  if(
    request.url.includes('login')
    || request.url.includes('logout')
    || request.url.includes('/pdf')
    || request.url.includes('/savepdf')
    || request.url.includes('sitemesh')
    || request.url.includes('auth-pin')
  ){
    
  }else{
    let auth = authenticator({request, context})
    userData = await auth.isAuthenticated(request, {
      failureRedirect: "/login",
    })
  }

  const data = {
    AUTH_REDIRECT_URL:  baseUrl,
    AUTH_0_DOMAIN:      context.cloudflare.env.AUTH_0_DOMAIN,
    AUTH_0_CLIENT_ID:   context.cloudflare.env.AUTH_0_CLIENT_ID,
    VERSION_NUMBER:     context.cloudflare.env.CF_PAGES_COMMIT_SHA ? context.cloudflare.env.CF_PAGES_COMMIT_SHA : 'dev',
    ENVIRONMENT:        APPLICATION_ENV,
    GA_TAG_ID:          context.cloudflare.env.GA_TAG_ID,
    USER_ID:            userId,
    ROLE_NAME:          session.get("rolename"),
    currentURL:         request.url
  }



  const gaTrackingId = context.cloudflare.env.GA_TAG_ID

  const toastMessage = (await sessionStorage.getToastMessage(request)) || null
  return json({data, toastMessage, gaTrackingId, userData}, {
    headers: { 'Set-Cookie': await sessionStorage.clearToastMessage(request) },
  });

}

function App() {
  
  const data = useLoaderData()
  const { toastMessage } = useLoaderData<typeof loader>()

  useEffect(() => {
    if (!toastMessage) {
      return
    }
    switch (toastMessage.status) {
      case 0:
        toast.error(toastMessage.message, {
          position: 'top-right',
        })
        break;

      default:
        toast.success(toastMessage.message, {
          position: 'top-right',
        })
        break;
    }
    
  }, [toastMessage])

  const location = useLocation();

  // useEffect(() => {

  //   if(data.userData && (APPLICATION_ENV == 'preproduction' || APPLICATION_ENV == 'production')){

  //     const userData = data.userData
  //     const userCreatedDate = new Date(userData.created_at.substr(0, 10))

  //     if (typeof document !== 'undefined' && parent) {
  //       Intercom({
  //         app_id: 'fouakxqc',
  //         user_hash: userData.intercom_hash,
  //         custom_launcher_selector: '#intercomLauncher',
  //         name: userData.displayName, // Full name
  //         user_id: userData.userid, // a UUID for your user
  //         email: userData.email, // the email for your user
  //         created_at:  Math.floor(userCreatedDate.getTime() / 1000),
  //         Role: userData.rolename,
  //         Version: APPLICATION_VERSION,
  //         "Version_Counter": +(APPLICATION_VERSION.replace(/\./g,'')),
  //         company: {
  //           company_id: userData.cid, // a UUID for your company
  //           name: userData.companyname,
  //         }
  //       });
  //     }

  //   }

  // }, [location, data])

  useEffect(() => {

    if(data.userData && (APPLICATION_ENV == 'preproduction' || APPLICATION_ENV == 'production')){

      const userData = data.userData
      const userCreatedDate = new Date(userData.created_at.substr(0, 10))
      
      let d = document;
      let i: any = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args: any) {
        i.q.push(args);
      };
      window.intercomSettings = {
        app_id: 'fouakxqc',
        user_hash: userData.intercom_hash,
        custom_launcher_selector: '#intercomLauncher',
        name: userData.displayName, // Full name
        user_id: userData.userid, // a UUID for your user
        email: userData.email, // the email for your user
        created_at:  Math.floor(userCreatedDate.getTime() / 1000),
        Role: userData.rolename,
        Version: APPLICATION_VERSION,
        "Version_Counter": +(APPLICATION_VERSION.replace(/\./g,'')),
        company: {
          company_id: userData.cid, // a UUID for your company
          name: userData.companyname,
        }
      }
      window.Intercom = i;
      let l = function () {
        let _a, _b;
        let s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${((_a = window.intercomSettings) === null || _a === void 0 ? void 0 : _a.app_id)}`;
        let x = d.getElementsByTagName('script')[0];
        (_b = x.parentNode) === null || _b === void 0 ? void 0 : _b.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      }
      else if (window.attachEvent) {
        window.attachEvent('onload', l);
      }
      else {
        window.addEventListener('load', l, false);
      }

    }

  }, []);

  useEffect(() => {
    if(data.userData && (APPLICATION_ENV == 'staging' || APPLICATION_ENV == 'preproduction' || APPLICATION_ENV == 'production')){

      Sentry.setUser({
        id: data.userData.userid,
        email: data.userData.email
      });

    }
  }, [])

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1" />
        <meta name="format-detection" content="telephone=no, date=no, email=no, address=no" />
        <style data-fullcalendar />
        <Meta />
        <Links />
      </head>
      <Auth0Provider
        domain={data.data.AUTH_0_DOMAIN}
        clientId={data.data.AUTH_0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: `${data.data.AUTH_REDIRECT_URL}/signin`
        }}
      >
      <body className="h-full bg-gray-100">
        <div className="h-full flex">
          <Outlet />
          <Toaster />
        </div>
        <ScrollRestoration />
        <Scripts />
      </body>
      </Auth0Provider>
    </html>
  );
}

export default App;